<html moznomarginboxes mozdisallowselectionprint>

<head>
  <style type="text/css">

  </style>
</head>

<body class="iemtprint">
    <table style="background:#fff !important;">
        <thead>
            <tr>
                <td>
                    <div class="header-space">&nbsp;</div>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div class="watermark">
                        <p>IETM Printed <span class="waterprint-time"></span></p>
                        <p>
                            <span class="waterprint-by" style="padding-right:10px"></span>
                        </p>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="width:100%;background:#fff !important;">

                    <div id="printblock" class="content"></div>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td>
                    <div class="footer-space">&nbsp;</div>
                </td>
            </tr>
        </tfoot>
    </table>
    <div class="header" id="printheader">
        <table>
            <tbody>
                <tr>
                    <td style="width:15%;padding:10px 15px">
                        <img id="clientlogoprint" src="" height="40">
                    </td>
                    <td style="width:85%;text-align:right;padding:10px 15px">
                        <div id="producttitleprint"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="footer">
        <table >
            <tbody>
                <tr>
                    <td style="width:25%;padding-left:10px">
                        <div>IETM Printed</div>
                    </td>
                    <td style="width:25%;text-align:center">
                        <div id="footer-by"></div>
                    </td>
                    <td style="width:25%;text-align:right;padding-right:10px">
                        <div id="printitme"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</body>

</html>
