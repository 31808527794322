import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module'
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { VerticalMenuComponent } from './pages/vertical-menu/vertical-menu.component';
import {CommonService} from './utils/common.service';
import {MenuService} from './utils/menu.service';
import { LandingComponent } from './pages/landing/landing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS} from "@angular/common/http";
import {DataService} from './utils/data.service';
import {PrintModule} from './ietmprint/print.module';
import { HelperService} from './utils/httpInterceptor/helper.service';
import { HttpClientInterceptor } from './utils/httpInterceptor';
import { LoginAuth } from './utils/loginauth.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};
@NgModule({
  declarations: [
    AppComponent,
    VerticalMenuComponent,
    LandingComponent

  ],
  imports: [
    PerfectScrollbarModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    routing,
    HttpClientModule,
    BrowserAnimationsModule,
    PrintModule
  ],
  entryComponents:[
    VerticalMenuComponent
  ],

  providers: [ LoginAuth,CommonService,HelperService,DataService, MenuService,  { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
