import { Injectable } from "@angular/core";
@Injectable()
export class DataService {

    public annotations = [
        {  id: 1,page_id:1,annotated_text: 'Feed Back to Administrator',description:"Sed ut perspiciatis unde omnis iste natus error ...",annotation_type:1,creation_date:'2020-03-29 11:21:59',created_by:1,created_by_name:'Amsi Maganti' },
        {  id: 1,page_id:1,annotated_text: 'Note Annotation',description:"Test of notest",annotation_type:2,creation_date:'2020-03-29 11:21:59',created_by:1 ,created_by_name:'Amsi Maganti' }
    ]
    public bookmarks = [
        {  id: 1,page_id:1,bookmark_name: 'Feed Back to Administrator',created_by:1 ,created_by_name:'Amsi Maganti'},
        {  id: 1,page_id:2,bookmark_name: 'Feed Back to Administrator',created_by:1,created_by_name:'Amsi Maganti'}
    ]
}