import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment'
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as $ from "jquery";
import { Location } from '@angular/common';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public triggerzoom = new Subject<any>();
  public triggersidemnu = new Subject<any>();
  public triggerpageannots = new Subject<any>();
  public triggerbookmark = new Subject<any>();
  public triggerlocalsearch = new Subject<any>();
  public triggerlocalsearchCompletion = new Subject<any>();
  public triggerlocalsearchPagging = new Subject<any>();
  public triggerglobalSearch = new Subject<any>();
  public triggerglobaltolocalsearch= new Subject<any>();
  public triggerhistoryload= new Subject<any>();
  public triggerviewerclose= new Subject<any>();
  public triggerietmdocs= new Subject<any>();
  public triggerrevision=new Subject<any>();
  public triggermodulechange=new Subject<any>();
  public localsearchTerm = "battery";
  public selected_pageid = 0;
  public totalpage_annotations = 0;
  public user_types=['Publisher','Administrator','Operator','Maintianer'];
  public modules_list=[];
  public page_bookmarked=0;
  public users_history={id:0,full_name:'',user_id:'',creation_date:''};
  public media_setup:any={};
  public backupstatuses = ['', 'Inprogress', 'Success', 'Failed'];
  public enable_globalsearch=0;
  public history_loaded=0;
  public openedcategory=0;
  public uridata:any={id:'',type:''};
  public showintrduction=1;
  public inventory_transactions:any={id:0,type:'0',code:''};
  public selected_annotation:any={};
  public  opened_list_filters:any={};

  public explainer_types=['','Word Explainer','Image Explainer','Video Explainer'];
  // /, { id: '5', name: "Inventory", url: 'inventory' }
  public page_types = [{ id: '1', name: "IETM Docs", url: 'content' }, { id: '2', name: "Manuals", url: 'manuals' }, { id: '3', name: "Illustrations", url: 'illustrations' }, { id: '4', name: "Glossary", url: 'glosary' },

// { id: '5', name: "Inventory", url: 'inventory' }
  // { id: '6', name: "Logs", url: 'logs' }
];
  public CKEditorConfig=
    {

  };
  public managepage_data={id:0,category_id:0,module_id:0,parent:0,doc_standard:0,is_encrypted:0}
  public app_settings = { theme: 'teal-light', sidenavIsOpened: true, sidenavIsPinned: true, menu: 'vertical', fixedHeader: true };
  //indigo-light, teal-light, red-light, blue-dark, green-dark, pink-dark
  ////horizontal , vertical
  public live_url = environment.sevurl;
  public live_imgurl=environment.staticimgpth;
  async getmodulesdata(){
    var udata= await this.getuser_session();
    this.postData({ sourceid: 'list/calldbproc', info: { procname: 'module_data', vals: [udata['user_type']] } }).subscribe((data: any) => {
    //  alert(JSON.stringify("Data"+data));
      if (data.status == 1) {

        this.modules_list = data.data[0];
      }
    });
  }
  constructor(public location:Location,public http: HttpClient, private snackBar: MatSnackBar,public router:Router) {
   this.getmodulesdata();
   }
   public locationback(){
    this.location.back();
  }
  public getpagecontent(id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/html; charset=utf-8');
    return this.http.get("./assets/content/page" + id + '.html', { headers, responseType: 'text' });
  }
  public sendglobaltolocalsearch(message: string) {
    this.triggerglobaltolocalsearch.next(message);
  }
  public getglobaltolocalsearch(): Observable<any> {
    return this.triggerglobaltolocalsearch.asObservable();
  }
  public sendglobalsearch(message: string) {
    this.triggerglobalSearch.next(message);
  }
  public getglobalSearch(): Observable<any> {
    return this.triggerglobalSearch.asObservable();
  }
  public sendsidemnu(message: string) {
    this.triggersidemnu.next(message);
  }
  public sendzoom(message: number) {
    this.triggerzoom.next(message);
  }
  public getzoom(): Observable<any> {
    return this.triggerzoom.asObservable();
  }
  public sendrevision(message: string) {
    this.triggerrevision.next(message);
  }
  public getrevision(): Observable<any> {
    return this.triggerrevision.asObservable();
  }
  public getsidemnu(): Observable<any> {
    return this.triggersidemnu.asObservable();
  }
  public sendpageannots(message: string) {
    this.triggerpageannots.next(message);
  }
  public getpageannots(): Observable<any> {
    return this.triggerpageannots.asObservable();
  }
  public sendbookmark(message: string) {
    this.triggerbookmark.next(message);
  }
  public getbookmark(): Observable<any> {
    return this.triggerbookmark.asObservable();
  }
  public sendlocalsearch(message: string) {
    this.triggerlocalsearch.next(message);
  }
  public getlocalsearch(): Observable<any> {
    return this.triggerlocalsearch.asObservable();
  }
  public sendlocalsearchCompletion(message: string) {
    this.triggerlocalsearchCompletion.next(message);
  }
  public getlocalsearchCompletion(): Observable<any> {
    return this.triggerlocalsearchCompletion.asObservable();
  }
  public sendlocalsearchPagging(data) {
    this.triggerlocalsearchCompletion.next(data);
  }
  public getlocalsearchPagging(): Observable<any> {
    return this.triggerlocalsearchCompletion.asObservable();
  }
  public gethistoryload(): Observable<any> {
    return this.triggerhistoryload.asObservable();
  }
  public sendhistoryload(data) {
    this.triggerhistoryload.next(data);
  }
   public getviewerclose(): Observable<any> {
    return this.triggerviewerclose.asObservable();
  }
  public sendviewerclose(data) {
    this.triggerviewerclose.next(data);
  }
  public getietmdocs(): Observable<any> {
    return this.triggerietmdocs.asObservable();
  }
  public sendietmdocs(data) {
    this.triggerietmdocs.next(data);
  }
  public getmodulechange(): Observable<any> {
    return this.triggermodulechange.asObservable();
  }
  public setmodulechange(data) {
    this.triggermodulechange.next(data);
  }

  public openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
    });
  }
  public postData<T>(data: object): Observable<T> {
    //console.log(data['sourceid']);
    // console.log(JSON.stringify(data['info']));
    return this.http.post<T>(data['sourceid'], data['info']);
  }
  public getuser_session(){
    return JSON.parse(localStorage.getItem('ietm_session'));
  }
  public setuser_session(data){
    localStorage.setItem('ietm_session',JSON.stringify(data))
  }
  public openpdf(br){
    window.open(br, '_blank');
  }
  makeFileRequest(url: string, params: {}, files: Array<File>) {
    return new Promise((resolve, reject) => {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      formData.append("data", JSON.stringify(params));
      // console.log(formData);
      for (var i = 0; i < files.length; i++) {
        formData.append("uploads[]", files[i], files[i].name);
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.open("POST", environment.sevurl + url, true);
      xhr.send(formData);
    });
  }
  public async logout(){
    this.showintrduction=1;
    var udata=await this.getuser_session();
    this.postData({ sourceid: 'ietm/userlogout', info:{ id: udata['logid']}})
    .subscribe((data: any) => {
      localStorage.setItem('ietm_session', JSON.stringify({ }));
      this.router.navigate(['/']);
    });
  }

  public async printPage() {
    var timedt = moment(new Date()).format('lll');
    $('#printblock').html($('#printtable').html());
    $('#clientlogoprint').attr("src",$('#clientlogo').attr('src'));
    $('#userlogoprint').html($('#userlogo').html());
    $('#producttitleprint').html($('#table-title span').text());
    $('#printitme').html(timedt);
    $('.waterprint-time').html(timedt);
    $("#footer-by").html($('#logged-user').text());
  //  $('.waterprint-by').html('Anadrone Systems Private Limited (Publisher)');
   $('.waterprint-by').html('Smartify (Publisher)');
    //$('.waterprint-by').html($('#logged-user').text());
    window.print();
   // $('#table-title mat-icon').css("display",'block');
  }
  public openpage(module_id,page_id){
    let moduleData:any=this.modules_list.filter(x=>x.id==+module_id)[0];
    //console.log(moduleData,'===');
    localStorage.setItem('ietm_modsel', JSON.stringify({ id: moduleData.id, title: moduleData.title, doc_standard:  moduleData.doc_standard,is_encrypted:  moduleData.is_encrypted}));
    this.router.navigate(['../dash/itemcore/content/page/'+page_id]);
  }
}
