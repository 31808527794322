import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';
import { tap } from "rxjs/operators";
//import 'rxjs/add/operator/do';
import { environment } from '../../../environments/environment'
@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  public baseUrl: string =environment.sevurl;
  constructor (private helper: HelperService) {
 
  }
  public intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //alert(req.method+">>>///////////////")
    if( req.method=='POST'){
      const request = req.clone({
        url: this.baseUrl + req.url,
        setHeaders: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' +environment.PHASEPRASE,
          'x-access-token':  'no_token',
        },
        // withCredentials: true, // same origin not use
      });
      return next.handle(request).pipe(
        tap((res: HttpResponse<any>) => {
        if (res instanceof HttpResponse) {
          this.helper.successHelper(res);
        }
        return res.body;
      }, (err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          this.helper.errorHelper(err);
        }
        return err.error;
      }, () => {
        //
      }));
    }else{
      const request = req.clone({
        url:req.url,
        setHeaders: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
        // withCredentials: true, // same origin not use
      });
      return next.handle(request).pipe(tap((res: HttpResponse<any>) => {
        if (res instanceof HttpResponse) {
          this.helper.successHelper(res);
        }
        return res.body;
      }, (err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          this.helper.errorHelper(err);
        }
        return err.error;
      }, () => {
        //
      }));
    }

  }
}
