import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser } from '@angular/common';
import {CommonService} from './common.service';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot ,ActivatedRoute } from '@angular/router';
@Injectable()
export class LoginAuth implements CanActivate {
  public user_session: any = {}
  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object,public cs:CommonService) { }
  redirectUrl: string;
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (isPlatformBrowser(this.platformId)) {  
     // alert(JSON.stringify(localStorage.getItem('ietm_session')));
      if (localStorage.getItem('ietm_session') == null || localStorage.getItem('ietm_session') == '{}' || localStorage.getItem('ietm_session') ==undefined) {
        if(state.url !="/"){
          this.router.navigate(['/']);
        }else{
          return true;
        }
      }
      else {
        if(state.url=="/"){
          this.router.navigate(['/dash']);
         }
         //console.log("else//////");
         return true;
      }
    }
  }
}
