import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable()
export class HelperService {

  public horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  public verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(public snackBar: MatSnackBar) {

  }
  public successHelper(res: HttpResponse<any>): void {
    switch (res.status) {
      case 201:
        const arr = res.url.split('/api');
        if (arr[arr.length - 1] === '/login') {
          this.openSnackBar('Web Server Error');
        } else if (arr[arr.length - 1] === '/upload-file') {
          this.openSnackBar('Web Server Error');
        } else {
          this.openSnackBar('Web Server Error');
        }
        break;
      default:
        //
        break;
    }
  }
  openSnackBar(message: string) {
    // this.snackBar.open(message);
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = 3000;
    this.snackBar.open(message, 'Close', config);
  }

  public errorHelper(err: HttpErrorResponse): void {
    switch (err.status) {
      case 400:
        const arr = err.url.split('/api');
        if (arr[arr.length - 1] === '/login') {
          this.openSnackBar('Web Server was down');
        } else if (arr[arr.length - 1] === '/upload-file') {
          this.openSnackBar('Web Server was down');
        } else {
          this.openSnackBar(err.error.msg || err.error.data);
        }
        break;
      case 401:

        this.openSnackBar("Restricted Access");
      //  localStorage.setItem('RedirectUrl', location.pathname);
        setTimeout(() => {
          location.href = 'login';
        }, 1000);
        break;
      case 403:
        this.openSnackBar('Web Server Error');
        break;
      case 404:
        //this.openSnackBar('Services Not found');
        break;
      case 405:
        this.openSnackBar('Web Server Error');
        break;
      case 406:
        this.openSnackBar('Web Server Error');
        break;
      case 406:
        this.openSnackBar('Web Server Error');
        break;
      case 500:
        this.openSnackBar(err.error.msg || err.error.data);
        break;
      case 503:
        this.openSnackBar('Web Server Error');
        break;
      case 504:
        this.openSnackBar('Web Server Error');
        break;
      default:
       // this.openSnackBar('Web Server Error');
        break;
    }
  }
}
