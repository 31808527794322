// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  PHASEPRASE:'ZW5hMjBwamgxNToyNmJhZjBjZjQ5Y2Q4ZmM4NGE5NGYxMmVhNGJiM2NmZA==',
 // staticimgpth:'http://localhost:5909/media/',
 //sevurl:'http://localhost:5909/',
   staticimgpth:'https://mmlsp.smatoapps.com:5909/media/',
   sevurl:'https://mmlsp.smatoapps.com:5909/',
  authorenc:'40be4e59b9a2a2b5dffb918c0e86b3d7'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
