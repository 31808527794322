<mat-toolbar color="primary" class="flex-p-x">
    <div fxLayout="row" fxLayoutAlign="start center" class="w-100" >

            <!-- <a mat-raised-button color="accent" routerLink="/" class="small-logo" style="margin-right: 10px;">C</a>
            <a mat-raised-button color="accent" routerLink="/" class="small-logo">U</a> -->

            <img   id="clientlogo"  src="./assets/img/client-logo1.png" height="40"  style="margin-right:15px;color:#fff">
            <img   id="clientlogo"  src="./assets/img/client-logo2.png" height="40" style="background-color:#fff">

            <div fxFlex fxHide [fxHide.gt-sm]="false" style="text-align: center;">
                <a class="logo" id="producttitle">MMLSP IETM</a>
            </div>

        <!-- <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button [matMenuTriggerFor]="helpMenu" #helpMenuTrigger="matMenuTrigger">
                        <mat-icon>help</mat-icon>
                    </button>
            <mat-menu #helpMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu flags">
                <span (mouseleave)="helpMenuTrigger.closeMenu()">
                  <a mat-menu-item routerLink="../help/content/3">
                    About IETM
                </a>
                        </span>
            </mat-menu>
        </div> -->
    </div>
</mat-toolbar>
<div class="bg-login">
    <div fxLayout="row wrap" fxLayoutAlign.gt-sm="start start" fxLayoutAlign="space-around center"  class="h-100">
        <form [formGroup]="manageForm" (ngSubmit)="submitform(mForm)" #mForm="ngForm" fxFlex="90" fxFlex.gt-sm="30" fxFlex.sm="60" *ngIf="enable_forgotform==0">
            <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z12 box-content-inner" style="position: absolute;right: 20px;">
                <mat-card-header class="w-100">
                    <mat-card-title class="w-100">Login</mat-card-title>
                </mat-card-header>
                <mat-card-content  class="w-100">
                    <mat-divider></mat-divider>
                    <br>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                        <mat-label>User Name</mat-label>
                        <input matInput placeholder="" autocomplete="off" formControlName="user_name">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                        <mat-label>Password</mat-label>
                        <input matInput placeholder="" autocomplete="off" type="password" formControlName="user_password">
                    </mat-form-field>
                    <p class="formvalidation">{{form_validation}}</p>
                    <p fxLayout="row" fxLayoutAlign="space-between center"><button mat-raised-button color="primary" class="mat-elevation-z12 box-button" type="submit">Submit</button> <button mat-button color="accent" type="button" (click)="openforgotform()" *ngIf="formvalidation_status==4">Forgot Password?</button></p>
                </mat-card-content>
            </mat-card>
        </form>
        <form (ngSubmit)="validate_forgotpwform()" #mForm="ngForm" fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60" *ngIf="enable_forgotform==1" style="position: absolute;right: 20px;">
            <mat-card fxLayout="column" fxLayoutAlign="start center" class="mat-elevation-z12 box-content-inner">
                <mat-card-header>
                    <mat-card-title>Answer your Security Questions</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-divider></mat-divider>
                    <br>
                    <ng-template [ngIf]="forgotpassword_data[0][0] !='' && seqs_loaded==1">
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                            <mat-label>{{forgotpassword_data[0][0]}}?</mat-label>
                            <input requied matInput placeholder="" autocomplete="off" id="SecondQ1" name="SecondQ1" [(ngModel)]="forgotpwd_answers[0]">
                        </mat-form-field>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                            <mat-label>{{forgotpassword_data[1][0]}}?</mat-label>
                            <input requied matInput placeholder="" autocomplete="off" id="SecondQ" name="SecondQ" [(ngModel)]="forgotpwd_answers[1]">
                        </mat-form-field>
                        <p class="formvalidation">{{form_validation}}</p>
                        <p fxLayout="row" fxLayoutAlign="space-between center"><button mat-raised-button color="primary" type="submit">Submit</button> <button mat-button color="accent" type="button" (click)="backtologin()">Back to Login</button></p>
                    </ng-template>
                    <ng-template [ngIf]="!forgotpassword_data[0][0] && seqs_loaded==1">
                        <p class="formvalidation">You have not Updated Security Questions, Contact Administrator</p>
                        <p fxLayout="row" fxLayoutAlign="space-between center"><button mat-button color="accent" type="button" (click)="backtologin()">Back to Login</button></p>
                    </ng-template>
                </mat-card-content>
            </mat-card>
        </form>
        <form (ngSubmit)="updatepwd()" #changePwdFormer="ngForm" fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60" *ngIf="enable_forgotform==2">
            <mat-card fxLayout="column" fxLayoutAlign="start center" class="mat-elevation-z12 box-content-inner">
                <mat-card-header>
                    <mat-card-title>Set a new Password</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-divider></mat-divider>
                    <br>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                        <mat-label>New Password</mat-label>
                        <input autocomplete="off" required matInput class="form-control" type="{{showpassword2?'password':'text'}}" name="newpwd" id="newpwd" [(ngModel)]="resetpwds.newpwd" #newpwd="ngModel">
                        <mat-icon style="cursor:pointer" matSuffix (click)="showpassword2 = !showpassword2">{{showpassword2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-hint *ngIf="newpwd.errors?.required  &&  retypepwd.dirty">Please provide input</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                        <mat-label>Retype Password</mat-label>
                        <input autocomplete="off" matInput required (keydown.space)="$event.preventDefault()" class="form-control" type="{{showpassword3?'password':'text'}}" id="retypepwd" name="retypepwd" [(ngModel)]="resetpwds.retypepwd" #retypepwd="ngModel">
                        <mat-icon style="cursor:pointer" matSuffix (click)="showpassword3 = !showpassword3">{{showpassword3 ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-hint *ngIf="retypepwd.errors?.required  &&  retypepwd.dirty">Please provide input</mat-hint>
                    </mat-form-field>
                    <p class="formvalidation">{{form_validation}}</p>
                    <p fxLayout="row" fxLayoutAlign="space-between center"><button mat-raised-button color="primary" type="submit">Submit</button> <button mat-button color="accent" type="button" (click)="backtologin()">Back to Login</button></p>
                </mat-card-content>
            </mat-card>
        </form>
    </div>
</div>
