import { NgModule } from '@angular/core';
import { Routes, RouterModule  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import {LandingComponent} from './pages/landing/landing.component';
import { LoginAuth } from './utils/loginauth.service';
export const routes: Routes = [
  { path: '',  component: LandingComponent ,canActivate:[LoginAuth]},
  { path: 'dash',  loadChildren: () => import('./pages/dash/dash.module').then(m => m.DashboardModule) ,canActivate:[LoginAuth]}
];
  export const routing = RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,  // <- uncomment this line for disable lazy load
    // useHash: true
});
