import { Component, OnInit } from '@angular/core';
XMLHttpRequest
@Component({
  selector: 'ietm-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css']
})
export class PrintPageComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}
