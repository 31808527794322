import { Component } from '@angular/core';
import {CommonService} from './utils/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'smartify-ietm';
  constructor(public cs:CommonService) {

  }
}
