import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common'
import { CommonService } from '../../utils/common.service';
import { Md5 } from 'ts-md5/dist/md5';
import { environment } from '../../../environments/environment'
import * as moment from 'moment';
//import { ConsoleReporter } from 'jasmine';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  public manageForm: FormGroup;
  public form_validation = "";
  public enable_forgotform = 0;
  public formvalidation_status = 0;
  showpassword2 = true;
  showpassword3 = true;
  public resetpwds = { newpwd: '', retypepwd: "" };
  public forgotpwd_userid = 0;
  forgotpassword_data = [['', ''], ['', '']];
  public seqs_loaded = 0;
  forgotpwd_answers = ['', ''];
  public userSession: any = {};
  constructor(public formBuilder: FormBuilder, private location: Location, public cs: CommonService, public router: Router) {
    this.manageForm = this.formBuilder.group({
      'id': [null],
      'user_name': [''],
      'user_password': ['']
    });

  }
  openforgotform() {
    this.seqs_loaded = 0;
    this.enable_forgotform = 1;
    this.form_validation = "";
    this.forgotpassword_data = [['', ''], ['', '']];
    this.forgotpwd_answers = ['', ''];
    this.cs.postData({ sourceid: 'ietm/forgotpwd', info: { id: this.forgotpwd_userid } })
      .subscribe(data => {
        this.forgotpassword_data = JSON.parse(data['results'][0]['security_questions']);
        this.seqs_loaded = 1;
        this.forgotpwd_userid = data['results'][0]['id'];
      })

  }
  updatepwd() {
    if (this.resetpwds.newpwd == this.resetpwds.retypepwd) {
      const md5 = new Md5();
      this.cs.postData({ sourceid: 'ietm/resetpwd', info: { id: this.forgotpwd_userid, resetpwd: md5.appendStr(this.resetpwds.newpwd).end() } })
        .subscribe(data => {
          // alert(JSON.stringify(data));
          if (data['status'] == 1) {
            // this.mdls.dismiss();
            this.enable_forgotform = 0;
            this.resetpwds = { newpwd: '', retypepwd: "" };
            this.form_validation = "";
            this.cs.openSnackBar("Updated Pasword Sucessfully", 'Close');

          } else {
            this.form_validation = "An Error has occurred";
          }
        })
    } else {
      this.form_validation = "New and Re-enter Passwords don't match";
    }
  }
  validate_forgotpwform() {
    this.form_validation = "";
    if (this.forgotpwd_answers[0] == '' || this.forgotpwd_answers[1] == '') {
      this.form_validation = "Answer all Questions";

    } else {
      if (this.forgotpwd_answers[0].toString().toLowerCase() == this.forgotpassword_data[0][1].toString().toLowerCase() &&
        this.forgotpwd_answers[1].toString().toLowerCase() == this.forgotpassword_data[1][1].toString().toLowerCase()) {
        this.enable_forgotform = 2;
      } else {
        this.form_validation = "Invalid Answers";
      }
    }

  }
  backtologin() {
    this.manageForm.patchValue({
      'id': '',
      'user_name': '',
      'user_password': ''
    });
    this.enable_forgotform = 0;
  }
  submitform(form) {
    // console.log('===');
    // this.form_validation="";
    if (form.valid) {
      // var a: any = moment('2024-01-31');
      // var b: any = moment();
      // //console.log(b.diff(a, 'days'), "test");
      // if (b.diff(a, 'days') < 0) {

        const md5 = new Md5();
        if (this.manageForm.value.user_name == 'publisher') {
          if (md5.appendStr(this.manageForm.value.user_password).end() == environment.authorenc) {
            localStorage.setItem('ietm_session', JSON.stringify({ id: 0, full_name: 'Smart Publisher', user_id: '0000', user_type: 0, logid: 0 }));
            //alert(JSON.stringify({ id: 0, full_name:'Publisher', user_id:'0000', user_type:0, logid: 0 }));
            this.router.navigate(['/dash']);
          } else {
            this.form_validation = "Invalid Username or Password";
            this.forgotpwd_userid = 0;
          }
        }
        else {
          this.cs.postData({ sourceid: 'ietm/userlogin', info: { user_name: this.manageForm.value.user_name, user_password: md5.appendStr(this.manageForm.value.user_password).end() } })
            .subscribe((data: any) => {
              //  alert(JSON.stringify(data));
              this.formvalidation_status = data.status;
              if (data.status == 1) {
                this.form_validation = '';
                var dta = data.results;
                // alert(JSON.stringify(dta));
                localStorage.setItem('ietm_session', JSON.stringify({ id: dta.id, full_name: dta.full_name, user_id: dta.user_id, user_type: dta.user_type, logid: dta.logid, showintrduction: 1 }));
                this.router.navigate(['/dash']);
              } else if (data.status == 2) {
                this.form_validation = "Invalid Username";
              } else if (data.status == 3) {
                this.form_validation = "Some error occured xxxxxx";
              } else if (data.status == 4) {
                // alert(data.results['id']);
                this.form_validation = "Invalid Password";
                this.forgotpwd_userid = data.results['id'];
              }
            });
        }
      // } else {
      //   this.form_validation = "Some error occured";
      // }
    }
    else {
      this.form_validation = "Fill Out Mandatory Fields";
    }
  }
  ngOnInit() {
  }
}
// import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
// import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { Location } from '@angular/common'
// import { CommonService } from '../../utils/common.service';
// import { Md5 } from 'ts-md5/dist/md5';
// import { environment } from '../../../environments/environment'
// import * as moment from 'moment';
// @Component({
//   selector: 'app-landing',
//   templateUrl: './landing.component.html',
//   styleUrls: ['./landing.component.scss']
// })
// export class LandingComponent implements OnInit {
//   public manageForm: FormGroup;
//   public form_validation = "";
//   public enable_forgotform = 0;
//   public formvalidation_status = 0;
//   showpassword2 = true;
//   showpassword3 = true;
//   public resetpwds = { newpwd: '', retypepwd: "" };
//   public forgotpwd_userid = 0;
//   forgotpassword_data = [['', ''], ['', '']];
//   public seqs_loaded = 0;
//   forgotpwd_answers = ['', ''];
//   public userSession:any={};
//   constructor(public formBuilder: FormBuilder, private location: Location, public cs: CommonService, public router: Router) {
//     this.manageForm = this.formBuilder.group({
//       'id': [null],
//       'user_name': [''],
//       'user_password': ['']
//     });

//   }
//   openforgotform() {
//     this.seqs_loaded = 0;
//     this.enable_forgotform = 1;
//     this.form_validation = "";
//     this.forgotpassword_data = [['', ''], ['', '']];
//     this.forgotpwd_answers = ['', ''];
//     this.cs.postData({ sourceid: 'ietm/forgotpwd', info: { id: this.forgotpwd_userid } })
//       .subscribe(data => {
//         this.forgotpassword_data = JSON.parse(data['results'][0]['security_questions']);
//         this.seqs_loaded = 1;
//         this.forgotpwd_userid = data['results'][0]['id'];
//       })

//   }
//   updatepwd() {
//     if (this.resetpwds.newpwd == this.resetpwds.retypepwd) {
//       const md5 = new Md5();
//       this.cs.postData({ sourceid: 'ietm/resetpwd', info: { id: this.forgotpwd_userid, resetpwd: md5.appendStr(this.resetpwds.newpwd).end() } })
//         .subscribe(data => {
//           // alert(JSON.stringify(data));
//           if (data['status'] == 1) {
//             // this.mdls.dismiss();
//             this.enable_forgotform = 0;
//             this.resetpwds = { newpwd: '', retypepwd: "" };
//             this.form_validation = "";
//             this.cs.openSnackBar("Updated Pasword Sucessfully", 'Close');

//           } else {
//             this.form_validation = "An Error has occurred";
//           }
//         })
//     } else {
//       this.form_validation = "New and Re-enter Passwords don't match";
//     }
//   }
//   validate_forgotpwform() {
//     this.form_validation = "";
//     if (this.forgotpwd_answers[0] == '' || this.forgotpwd_answers[1] == '') {
//       this.form_validation = "Answer all Questions";

//     } else {
//       if (this.forgotpwd_answers[0].toString().toLowerCase() == this.forgotpassword_data[0][1].toString().toLowerCase() &&
//         this.forgotpwd_answers[1].toString().toLowerCase() == this.forgotpassword_data[1][1].toString().toLowerCase()) {
//         this.enable_forgotform = 2;
//       } else {
//         this.form_validation = "Invalid Answers";
//       }
//     }

//   }
//   backtologin() {
//     this.manageForm.patchValue({
//       'id': '',
//       'user_name': '',
//       'user_password': ''
//     });
//     this.enable_forgotform = 0;
//   }
//   submitform(form) {
//     if (form.valid) {
//       const md5 = new Md5();
//       var a:any = moment('2023-11-31');
//       var b:any =moment();
//        if(b.diff(a, 'days')<0){
//         this.cs.postData({ sourceid: 'ietm/userlogin', info: { user_name: this.manageForm.value.user_name, user_password: md5.appendStr(this.manageForm.value.user_password).end() } })
//         .subscribe((data: any) => {
//           //  alert(JSON.stringify(data));
//           this.formvalidation_status = data.status;
//           if (data.status == 1) {
//             this.form_validation='';
//             var dta = data.results;
//             // alert(JSON.stringify(dta));
//             localStorage.setItem('ietm_session', JSON.stringify({ id: dta.id, full_name: dta.full_name, user_id: dta.user_id, user_type: dta.user_type, logid: dta.logid,showintrduction:1}));
//             this.router.navigate(['/dash']);
//           } else if (data.status == 2) {
//             this.form_validation = "Invalid Username";
//           } else if (data.status == 3) {
//             this.form_validation = "Some error occured";
//           } else if (data.status == 4) {
//             // alert(data.results['id']);
//             this.form_validation = "Invalid Password";
//             this.forgotpwd_userid = data.results['id'];
//           }
//         });
//        }else{
//         this.form_validation = "Some error occured";
//        }
//     }
//   }
//   async ngOnInit() {
//   }
// }
